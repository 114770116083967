import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CartItem {
    id: number;
    name: string;
    quantity: number;
    final_price: number;
    net_price: number;
}

interface CartState {
    items: CartItem[];
    subtotal: number;
    total: number;
    vat: number;
    delivery: number;
    lastOrderId?: number | null;
}

const calculateTotals = (items: CartItem[]): { total: number; vat: number; subtotal: number; delivery: number } => {
    // @ts-ignore
    const settings = JSON.parse(window.settings)
    const subtotal = items.reduce((sum, item) => sum + item.final_price * item.quantity, 0);
    const vat = items.reduce((sum, item) => sum + item.net_price * item.quantity, 0) * 0.2;
    const delivery = (subtotal > 0 &&(settings.min_free_order_amount ?? 0) >= subtotal) ? parseFloat(settings.delivery_price) : 0;
    const total = subtotal + delivery;
    return { subtotal, total, vat, delivery };
};

const loadCartFromLocalStorage = (): CartState => {
    try {
        const cartData = localStorage.getItem('cart');
        if (cartData) {
            return JSON.parse(cartData);
        }
    } catch (error) {
        console.error('Error loading cart from localStorage:', error);
    }

    return { items: [], total: 0, vat: 0, delivery: 0, subtotal: 0 };
};

const initialState: CartState = loadCartFromLocalStorage();

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        clearCart: (state: CartState) => {
            state.items = []
            state.total = 0;
            state.vat = 0;
            state.subtotal = 0;
            state.delivery = 0;
            state.lastOrderId = null;
        },
        addItem: (state, action: PayloadAction<{ id: number; name: string; quantity: number; final_price: number; net_price: number }>) => {
            const product = action.payload;
            const existingItem = state.items.find((item) => item.id === product.id);

            if (existingItem) {
                existingItem.quantity = product.quantity;
            } else {
                state.items.push({
                    id: product.id,
                    name: product.name,
                    quantity: product.quantity,
                    final_price: product.final_price,
                    net_price: product.net_price,
                });
            }

            const { subtotal, total, vat, delivery } = calculateTotals(state.items);
            state.total = total;
            state.vat = vat;
            state.subtotal = subtotal;
            state.delivery = delivery;
        },
        removeItem: (state, action: PayloadAction<number>) => {
            state.items = state.items.filter((item) => item.id !== action.payload);

            const { subtotal, total, vat, delivery } = calculateTotals(state.items);
            state.total = total;
            state.vat = vat;
            state.subtotal = subtotal;
            state.delivery = delivery;
        },
        updateQuantity: (state, action: PayloadAction<{ id: number; quantity: number }>) => {
            const { id, quantity } = action.payload;
            const item = state.items.find((item) => item.id === id);

            if (item) {
                if (quantity <= 0) {
                    state.items = state.items.filter((item) => item.id !== id);
                } else {
                    item.quantity = quantity;
                }
            }

            const { subtotal, total, vat, delivery } = calculateTotals(state.items);
            state.total = total;
            state.vat = vat;
            state.subtotal = subtotal;
            state.delivery = delivery;
        },
        setLastOrderId: (state, action: PayloadAction<number>) => {
            state.lastOrderId = action.payload;
        }
    },
});

export const { addItem, clearCart, removeItem, updateQuantity, setLastOrderId } = cartSlice.actions;
export default cartSlice.reducer;