import { AiOutlineDelete } from "react-icons/ai";
import { CiCircleCheck } from "react-icons/ci";
import { AiOutlineShoppingCart } from "react-icons/ai";
import {useAppDispatch, useAppSelector} from "../../hooks/redux.ts";
import {clearCart, removeItem, setLastOrderId, updateQuantity} from "../../redux/slices/cartSlice.ts";
import {useTranslation} from "react-i18next";
import {Button} from "../../components/common";
import { IoIosArrowBack } from "react-icons/io";
import {countriesOptions, currency} from "../../constants/defaultValues.ts";
import {FC, useEffect, useState} from "react";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import {useAuth} from "../../providers/AuthProvider.tsx";
import {placeOrder} from "../../services/apiService.ts";
import {Order} from "../../types/Order.ts";
import { DateTime } from "luxon";

const Cart: FC<{rounded?: boolean}> = ({rounded=true}) => {
    const [order, setOrder] = useState<Order | null>(null);
    const [step, setStep] = useState<number>(3);

    const goToCheckout = () => setStep(2);
    const goToCart = () => setStep(1);
    const goToSuccess = (orderEntity: Order) => {
        setOrder(orderEntity);
        setStep(3)
    };

    return <div className={`w-full bg-white h-full flex flex-col justify-between text-sm p-2 ${rounded && 'shadow-md rounded-bl-xl'}`}>
        {step === 1 && <CartComponent goToCheckout={goToCheckout} rounded={rounded}/>}
        {step === 2 && <CheckoutComponent goToCart={goToCart} goToSuccess={goToSuccess}/>}
        {step === 3 && <SuccessComponent goToCart={goToCart} order={order} />}
    </div>
}

const CartComponent: FC<{goToCheckout: Function, rounded?: boolean}> = ({goToCheckout, rounded=true}) => {
    // @ts-ignore
    const settings = JSON.parse(window.settings)
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const cartItems = useAppSelector((state) => state.cart.items);
    const total = useAppSelector((state) => state.cart.total);
    const deliveryPrice = useAppSelector((state) => state.cart.delivery);

    const handleQuantityChange = (id: number, quantity: number) => {
        if (quantity === 0) {
            dispatch(removeItem(id)); // Remove item if quantity is 0
        } else {
            dispatch(updateQuantity({ id, quantity }));
        }
    };

    return <div className={`h-full flex flex-col justify-between overflow-y-auto ${rounded && 'rounded-bl-2xl p-2'}`}>
        {cartItems.length === 0 ? (
            <div className="flex w-full flex-col items-center justify-center h-full">
                <AiOutlineShoppingCart size={100} className="text-gray-300" />
                <p className="text-gray-300">{t('label.cart_is_empty')}</p>
            </div>
        ) : (
            <div className={`overflow-x-auto ${rounded && 'shadow-md py-2 px-2 rounded-xl'}`}>
                <table className="min-w-full table-auto border-collapse">
                    <thead className="">
                    <tr>
                        <th className="p-4 text-left font-semibold">{t('field.product')}</th>
                        <th className="p-4 text-left font-semibold">{t('field.price')}</th>
                        <th className="p-4 text-left font-semibold">{t('field.total')}</th>
                        <th className="p-4 text-left font-semibold">{t('field.qty')}</th>
                        <th className="p-4 text-left font-semibold"></th>
                    </tr>
                    </thead>
                    <tbody className="gap-y-2 divide-y">
                    {cartItems.map((item) => (
                        <tr key={item.id}>
                            <td className="p-4 text-left">{item.name}</td>
                            <td className="p-4 text-left">
                                {item.final_price.toFixed(2)} {currency}
                            </td>
                            <td className="p-4 text-left">
                                {(item.final_price * item.quantity).toFixed(2)} {currency}
                            </td>
                            <td className="p-4 text-left">
                                <input
                                    type="number"
                                    min="0"
                                    value={item.quantity}
                                    onChange={(e) =>
                                        handleQuantityChange(
                                            item.id,
                                            Number(e.target.value)
                                        )
                                    }
                                    className="w-16 p-1 border border-gray-300 rounded text-center"
                                />
                            </td>
                            <td className="p-4 text-left">
                                <button
                                    onClick={() => dispatch(removeItem(item.id))}
                                    className="text-red-500 hover:underline"
                                >
                                    <AiOutlineDelete size={20} />
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )}
        <div className="pt-4">
            <table className="border-separate border-spacing-x-2 mb-2">
                {
                    deliveryPrice > 0 && <tr className="text-base">
                        <td><p className="font-bold text-primary">{t('label.delivery_price')}</p></td>
                        <td><p>{deliveryPrice} {currency}</p></td>
                    </tr>
                }
                {
                    deliveryPrice > 0 && <tr className="text-xs">
                        <td colSpan={2}><p>{t('title.free_delivery_sum', {
                            currency,
                            sum: settings.min_free_order_amount
                        })}</p></td>
                    </tr>
                }
                <tr className="text-xl">
                    <td><p className="font-bold text-primary">{t('label.cart_total')}</p></td>
                    <td><p>{total} {currency}</p></td>
                </tr>
            </table>
            <Button title={t('button.checkout')} onClick={() => goToCheckout()} disabled={cartItems.length === 0}/>
        </div>
    </div>
}

const weekDays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

const CheckoutComponent: FC<{ goToCart: Function, goToSuccess: Function }> = ({goToCart, goToSuccess}) => {
    // @ts-ignore
    const settings = JSON.parse(window.settings)
    const dispatch = useAppDispatch()
    const total = useAppSelector((state) => state.cart.total);
    const {t} = useTranslation();
    const paymentMethods = useAppSelector(state => state.paymentMethods.methods)
    const {user} = useAuth()
    const cartItems = useAppSelector((state) => state.cart.items);
    const [selectedAddress, setSelectedAddress] = useState<number | null>(user?.company?.addresses?.length ? user?.company?.addresses[0].id : null)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | null>(paymentMethods.length > 0 ? paymentMethods[0].id : null)
    const [errors, setErrors] = useState<string[]>([])
    const [deliveryDate, setDeliveryDate] = useState<any>(null);
    const { currentCity, items: cities } = useAppSelector((state) => state.cities);
    const availableDays = currentCity?.delivery_days || [];
    const [comment, setComment] = useState('')
    const deliveryPrice = useAppSelector((state) => state.cart.delivery);

    // @ts-ignore
    const getStartAndEndOfWeek = (date, offset = 0) => {
        const dt = DateTime.fromJSDate(date, { zone: "Europe/Warsaw" });

        const startOfWeek = dt.minus({ days: dt.weekday - 1 }).plus({ weeks: offset }).startOf("day").toJSDate();
        const endOfWeek = DateTime.fromJSDate(startOfWeek, { zone: "Europe/Warsaw" })
            .plus({ days: 6 })
            .toJSDate();

        return { startOfWeek, endOfWeek };
    };


    // @ts-ignore
    const tileDisabled = ({ date }) => {
        const today = DateTime.now().setZone("Europe/Warsaw").startOf("day").toJSDate();

        const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(today, 0);

        const compareDate = DateTime.fromJSDate(date, { zone: "Europe/Warsaw" }).startOf("day").toJSDate();

        const compareDayOfWeek = (DateTime.fromJSDate(date, { zone: "Europe/Warsaw" }).weekday - 1) % 7;
        const compareDayName = weekDays[compareDayOfWeek];

        const isAvailableDay = availableDays.includes(compareDayName);
        const isInCurrentWeek = compareDate >= startOfWeek && compareDate <= endOfWeek;
        const isPastDay = compareDate < today;
        const isToday = compareDate.getTime() === today.getTime();

        return !(isAvailableDay && isInCurrentWeek && !isPastDay && !isToday);
    };

    const handleAddressClick = (addressId: number) => {
        if (selectedAddress === addressId) {
            setSelectedAddress(null)
        } else {
            setSelectedAddress(addressId)
        }
    }

    useEffect(() => {
        setSelectedAddress(null)
    }, [currentCity?.id]);

    const handlePaymentMethodClick = (paymentMethodId: number) => {
        if (selectedPaymentMethod === paymentMethodId) {
            setSelectedPaymentMethod(null)
        } else {
            setSelectedPaymentMethod(paymentMethodId)
        }
    }

    const handlePlaceOrder = async () => {
        const newErrors = []
        if (!selectedAddress) newErrors.push('address')
        if (!selectedPaymentMethod) newErrors.push('payment_method')
        if (!deliveryDate) newErrors.push('delivery_date')
        setErrors(newErrors)
        if (!newErrors.length) {
            const order = await placeOrder({
                payment_method: selectedPaymentMethod!,
                address: selectedAddress!,
                deliver_at: deliveryDate!,
                items: cartItems.map(item => ({product: item.id, qty: item.quantity, price: item.final_price})),
                comment: comment,
                address_id: selectedAddress!
            })

            if (order?.id) {
                dispatch(clearCart())
                dispatch(setLastOrderId(order.id))
                goToSuccess(order);
            }
        }
    }

    return <div className="h-full p-2 rounded-bl-2xl flex flex-col justify-between gap-y-2 overflow-y-auto">
        <div className="flex flex-col gap-y-2">
            <div className="rounded-2xl shadow-md p-2">
                <div
                    className="flex flex-row gap-x-2 items-center cursor-pointer"
                    onClick={() => goToCart()}
                >
                    <IoIosArrowBack/> {t('label.back_to_cart')}
                </div>
            </div>
            <div className="flex flex-col items-center justify-center rounded-2xl shadow-md p-2">
                <p className="mb-2">{t('label.delivery_date')}:</p>
                <Calendar
                    tileDisabled={tileDisabled}
                    className="rounded-2xl"
                    value={deliveryDate}
                    onChange={setDeliveryDate}/>
                {errors.includes('delivery_date') &&
                    <p className="text-red text-sm py-2">{t('error.validation.select_delivery_date')}</p>}
            </div>
            <div className="rounded-2xl shadow-md px-4 py-2">
                <p className="mb-2">{t('label.choose_address')}:</p>
                <div className="divide-y">
                    {
                        !user?.company?.addresses?.filter(address => parseInt(address.city) === currentCity?.id).length && <p>
                            {t('error.validation.no_address_available')}
                        </p>
                    }
                    {
                        user?.company?.addresses?.filter(address => parseInt(address.city) === currentCity?.id).map(address => (
                            <div
                                onClick={() => handleAddressClick(address.id)}
                                className="flex flex-row gap-x-2 items-center cursor-pointer py-1"
                            >
                                <input type="checkbox" checked={selectedAddress === address.id}/>
                                {/* @ts-ignore */}
                                <p>{`${address.street}, ${cities.find(city => city?.id == parseInt(address.city))?.name}, ${address.postal_code}, ${countriesOptions.find(country => country.value == address.country)?.label}`}</p>
                            </div>
                        ))
                    }
                </div>
                {errors.includes('address') &&
                    <p className="text-red text-sm">{t('error.validation.select_address')}</p>}
            </div>
            <div className="rounded-2xl shadow-md py-2 px-4">
                <p className="mb-2">{t('label.choose_payment_method')}:</p>
                <div className="divide-y">
                    {
                        paymentMethods.map(paymentMethod => (
                            <div
                                onClick={() => handlePaymentMethodClick(paymentMethod.id)}
                                className="flex flex-row gap-x-2 items-center cursor-pointer py-1"
                            >
                                <input type="checkbox" checked={selectedPaymentMethod === paymentMethod.id}/>
                                <p>{paymentMethod.name}</p>
                            </div>
                        ))
                    }
                </div>
                {errors.includes('payment_method') &&
                    <p className="text-red text-sm">{t('error.validation.select_payment_method')}</p>}
            </div>

            <div className="rounded-2xl shadow-md py-2 px-4 w-full">
                <p className="mb-2">{t('label.comment')}:</p>
                <textarea className="rounded-md border border-gray-300 w-full py-1 px-2" name="comment" value={comment} onChange={(e) => setComment(e.target.value)} />
            </div>
        </div>
        <div className="pt-4">
            <table className="border-separate border-spacing-x-2 mb-2">
                {
                    deliveryPrice > 0 && <tr className="text-base">
                        <td><p className="font-bold text-primary">{t('label.delivery_price')}</p></td>
                        <td><p>{deliveryPrice} {currency}</p></td>
                    </tr>
                }
                {
                    deliveryPrice > 0 && <tr className="text-xs">
                        <td colSpan={2}><p>{t('title.free_delivery_sum', {currency, sum: settings.min_free_order_amount})}</p></td>
                    </tr>
                }
                <tr className="text-xl">
                    <td><p className="font-bold text-primary">{t('label.cart_total')}</p></td>
                    <td><p>{total} {currency}</p></td>
                </tr>
            </table>
            <Button title={t('button.place_order')} onClick={handlePlaceOrder}/>
        </div>
    </div>
}

const SuccessComponent: FC<{ goToCart: Function, order: Order | null }> = ({goToCart, order}) => {
    const {t} = useTranslation();

    if (!order) {
        goToCart()
        return <></>
    }

    return <div className="h-full p-2 rounded-bl-2xl flex flex-col justify-between gap-y-2 overflow-y-auto">
        <div className="flex w-full flex-col items-center justify-center h-full gap-y-2">
            <CiCircleCheck size={100} className="text-green-500"/>
            <p>{t('title.thank_you_for_order')}</p>
            {/* @ts-ignore */}
            <p>{t('title.order_id_is')} <b>{order?.id}</b></p>
            <p>{t('title.you_will_receive_invoice')}</p>
            <Button title={t('label.back_to_cart')} onClick={() => goToCart()} />
        </div>
    </div>
}

export default Cart
