import { FC, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import { Button, Logo } from "../../components/common";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const ResetPasswordPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { uid, token } = useParams<{ uid: string; token: string }>();

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const ResetPasswordSchema = Yup.object().shape({
        new_password: Yup.string()
            .required(t("error.validation.required"))
            .min(8, t("error.validation.password_too_short"))
            .matches(/[a-zA-Z]/, t("error.validation.password_latin")),
        confirm_password: Yup.string()
            .required(t("error.validation.required"))
            .oneOf([Yup.ref("new_password")], t("error.validation.password_mismatch")),
    });

    return (
        <AuthLayout>
            <div className="flex flex-col items-center justify-center h-screen">
                <Formik
                    initialValues={{ new_password: "", confirm_password: "" }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setError(null);
                        try {
                            await axios.post("/password/reset/confirm/", {
                                uid,
                                token,
                                new_password: values.new_password,
                                confirm_password: values.confirm_password,
                            });
                            setSuccess(true);
                        } catch (err: any) {
                            setError(t("error.invalid_reset_link"));
                        }
                        setSubmitting(false);
                    }}
                    validationSchema={ResetPasswordSchema}
                >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          errors,
                          touched
                      }) => (
                        <div className="flex flex-col w-full max-w-96 gap-y-4">
                            <div className="m-auto">
                                <Logo />
                            </div>

                            <h2 className="text-lg font-semibold text-center">
                                {t("title.reset_password")}
                            </h2>

                            {success ? (
                                <div className="text-center">
                                    <p className="text-green-500">{t("message.password_reset_success")}</p>
                                    <Button
                                        title={t("button.back_to_login")}
                                        onClick={() => navigate("/login")}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="flex flex-col gap-y-1">
                                        <label className="block text-sm font-medium leading-6">
                                            {t("label.new_password")}
                                        </label>
                                        <input
                                            id="new_password"
                                            name="new_password"
                                            type="password"
                                            value={values.new_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required
                                            className="w-full p-2 border rounded"
                                        />
                                        {errors.new_password && touched.new_password && (
                                            <div className="text-red-500 text-sm">{errors.new_password}</div>
                                        )}
                                    </div>

                                    <div className="flex flex-col gap-y-1">
                                        <label className="block text-sm font-medium leading-6">
                                            {t("label.confirm_password")}
                                        </label>
                                        <input
                                            id="confirm_password"
                                            name="confirm_password"
                                            type="password"
                                            value={values.confirm_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required
                                            className="w-full p-2 border rounded"
                                        />
                                        {errors.confirm_password && touched.confirm_password && (
                                            <div className="text-red-500 text-sm">{errors.confirm_password}</div>
                                        )}
                                    </div>

                                    {error && <p className="text-red-500 text-sm">{error}</p>}

                                    <Button title={t("button.reset_password")} onClick={handleSubmit} />
                                </>
                            )}
                        </div>
                    )}
                </Formik>
            </div>
        </AuthLayout>
    );
};

export default ResetPasswordPage;
