import {Provider} from "react-redux";
import {store} from "./redux/store.ts";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {
    EmailNeedConfirmation,
    ForgotPasswordConfirm,
    ForgotPasswordPage,
    LoginPage,
    RegistrationPage, ResetPasswordPage
} from "./pages/auth";
import {ProtectedRoute} from "./components/routes/ProtectedRoute.tsx";
import {HomePage} from "./pages/store";
import {AuthProvider} from "./providers/AuthProvider.tsx";
import {CatalogProvider} from "./contexts/CatalogContext.tsx";
import OrdersPage from "./pages/store/OrdersPage.tsx";
import EmailConfirmed from "./pages/auth/EmailConfirmed.tsx";

const App = () => {
    return <BrowserRouter>
        <Provider store={store}>
            <AuthProvider>
                <CatalogProvider>
                    <Routes>
                        <Route path="/" element={<ProtectedRoute>
                            <HomePage/>
                        </ProtectedRoute>}/>
                        <Route path="/orders" element={<ProtectedRoute>
                            <OrdersPage/>
                        </ProtectedRoute>}/>
                        <Route path="/login" element={<LoginPage />}/>
                        <Route path="/forgot-password" element={<ForgotPasswordPage />}/>
                        <Route path="/forgot-password-confirm" element={<ForgotPasswordConfirm />}/>
                        <Route path="/reset-password/:uid/:token" element={<ResetPasswordPage />} />
                        <Route path="/registration" element={<RegistrationPage />}/>
                        <Route path="/auth/confirmed" element={<EmailConfirmed />}/>
                        <Route path="/auth/need_confirmation" element={<EmailNeedConfirmation />}/>
                    </Routes>
                </CatalogProvider>
            </AuthProvider>
        </Provider>
    </BrowserRouter>
}

export default App
