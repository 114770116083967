export default {
    "label": {
        "email": "Email",
        "password": "Hasło",
        "need_help": "Potrzebujesz pomocy? Skontaktuj się z nami telefonicznie lub mailowo.",
        "cart_total": "Łączna kwota koszyka",
        "back_to_cart": "Powrót do koszyka",
        "choose_address": "Wybierz adres",
        "choose_payment_method": "Wybierz metodę płatności",
        "cart_is_empty": "Twój koszyk jest pusty",
        "company_name": "Nazwa firmy",
        "delivery_date": "Data dostawy",
        "manager": "Menedżer",
        "order": "Zamówienie",
        "not_found": "Nie znaleziono produktu",
        "total": "Suma",
        "created_at": "Utworzono",
        "no_addresses": "Brak zapisanych adresów",
        "comment": "Komentarz",
        "tin": "NIP",
        "invoice_address": "Adres faktury",
        "phone": "Telefon",
        "search_product": "Wyszukaj produkt",
        "delivery_price": "Cena dostawy",
        "delivery_time_note": "To nie jest konkretna godzina dostawy, ale okres, w którym restauracja przyjmuje dostawy, na przykład jej godziny otwarcia. Proszę wybrać minimum 4-godzinny przedział czasowy.",
        "net": "Netto",
        "gross": "Brutto",
        "vat": "VAT",
        "delivery_address": "Adres dostawy",
        "unavailable": "Wkrótce dostępne",
        "new_password": "Nowe hasło",
        "confirm_password": "Potwierdź hasło"
    },
    "button": {
        "login": "Zaloguj się",
        "checkout": "Zamówienie",
        "place_order": "Złóż zamówienie",
        "sign_up": "Zarejestruj się",
        "catalog": "Katalog",
        "change_password": "Zmień hasło",
        "company_information": "Informacje o firmie",
        "addresses": "Adresy",
        "add_new_address": "Dodaj nowy adres",
        "save": "Zapisz",
        "reset_password": "Zresetuj hasło",
        "send_reset_link": "Wyślij link resetujący",
        "back_to_login": "Wróć do logowania"
    },
    "title": {
        "dont_have_account": "Nie masz konta?",
        "sign_up_now": "Zarejestruj się teraz",
        "need_help": "Potrzebujesz pomocy? Skontaktuj się z nami telefonicznie lub mailowo.",
        "already_have_account": "Masz już konto?",
        "login_in": "Zaloguj się",
        "thank_you_for_order": "Dziękujemy za zamówienie!",
        "order_id_is": "ID twojego zamówienia to",
        "you_will_receive_invoice": "Wkrótce otrzymasz fakturę na swój zarejestrowany adres email.",
        "no_orders_yet": "Nie masz jeszcze żadnych zamówień",
        "free_delivery_sum": "Darmowa dostawa dostępna dla zamówień powyżej {{sum}} {{currency}}.",
        "forgot_password": "Zresetuj hasło",
        "remembered_password": "Przypomniałeś sobie hasło?",
        "email_sent": "E-mail wysłany",
        "reset_password": "Zresetuj hasło"
    },
    "error": {
        "validation": {
            "invalid_email": "Nieprawidłowy adres email",
            "invalid_password": "Nieprawidłowe hasło",
            "required": "Wymagane",
            "password_too_short": "Hasło jest za krótkie - minimum 8 znaków.",
            "password_latin": "Hasło może zawierać tylko litery łacińskie",
            "select_delivery_date": "Wybierz datę dostawy",
            "select_address": "Wybierz adres",
            "select_payment_method": "Wybierz metodę płatności",
            "passwords_do_not_match": "Hasła nie pasują do siebie",
            "city_required": "Miasto jest wymagane",
            "phone_required": "Telefon jest wymagany",
            "street_required": "Ulica jest wymagana",
            "street_255": "Ulica może mieć maksymalnie 255 znaków",
            "state_255": "Stan może mieć maksymalnie 255 znaków",
            "postal_required": "Kod pocztowy jest wymagany",
            "postal_255": "Kod pocztowy może mieć maksymalnie 20 znaków",
            "country_required": "Kraj jest wymagany",
            "country_255": "Kraj może mieć maksymalnie 100 znaków",
            "no_address_available": "Brak adresów dostępnych do wyboru. Proszę dodać adres lub sprawdzić wybrane miasto.",
            "delivery_time_start_required": "Wymagane jest podanie czasu rozpoczęcia dostawy",
            "delivery_time_end_required": "Wymagane jest podanie czasu zakończenia dostawy",
            "password_mismatch": "Hasła nie pasują do siebie."
        },
        "message": {
            "incorrect_current_password": "Nieprawidłowe aktualne hasło",
            "something_went_wrong": "Niestety, coś poszło nie tak. Spróbuj ponownie później lub skontaktuj się z nami telefonicznie lub mailowo.",
            "no_user_found": "Nie znaleziono aktywnego konta z podanymi danymi. Proszę potwierdzić swój email, aby aktywować konto."
        },
        "invalid_reset_link": "Nieprawidłowy lub wygasły link resetowania hasła."
    },
    "field": {
        "product": "Produkt",
        "price": "Cena",
        "total": "Suma",
        "qty": "Ilość",
        "name": "Nazwa",
        "image": "Obraz",
        "package": "Opakowanie",
        "weight": "Waga",
        "country": "Kraj",
        "repeat_password": "Powtórz hasło",
        "current_password": "Aktualne hasło",
        "new_password": "Nowe hasło",
        "street": "Ulica",
        "postal_code": "Kod pocztowy",
        "composition": "Skład",
        "features": "Cechy",
        "sku": "SKU",
        "net": "Netto",
        "gross": "Brutto",
        "vat": "VAT",
        "delivery_time": "Czas przyjmowania dostaw",
        "from": "od",
        "to": "do",
        "tin": "NIP",
        "invoice_address": "Adres faktury",
        "phone": "Telefon"
    },
    "header": {
        "account": "Konto",
        "logout": "Wyloguj się",
        "orders": "Zamówienia"
    },
    "message": {
        "password_updated": "Hasło zaktualizowane",
        "updated": "Zaktualizowano",
        "saved": "Zapisano",
        "email_confirmed": "Twój email został pomyślnie potwierdzony! Możesz teraz uzyskać dostęp do portalu i z niego korzystać. Dziękujemy za wybór naszej usługi!",
        "confirm_email": "Proszę potwierdzić swój email, aby rozpocząć korzystanie z portalu.",
        "forgot_password_instructions": "Wpisz swój adres e-mail, a my wyślemy Ci link do zresetowania hasła.",
        "check_email": "Wysłaliśmy Ci e-mail z linkiem do zresetowania hasła. Sprawdź swoją skrzynkę odbiorczą.",
        "password_reset_success": "Twoje hasło zostało pomyślnie zresetowane!"
    }
}
