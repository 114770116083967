import { FC } from "react";
import { AuthLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import { Button, Logo } from "../../components/common";
import { useNavigate } from "react-router-dom";

const ForgotPasswordConfirm: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <AuthLayout>
            <div className="flex flex-col items-center justify-center h-screen">
                <div className="flex flex-col w-full max-w-96 gap-y-4">
                    <div className="m-auto">
                        <Logo />
                    </div>

                    <h2 className="text-lg font-semibold text-center">
                        {t("title.email_sent")}
                    </h2>
                    <p className="text-center text-sm text-gray-600">
                        {t("message.check_email")}
                    </p>

                    <div>
                        <Button
                            title={t("button.back_to_login")}
                            onClick={() => navigate("/login")}
                        />
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
};

export default ForgotPasswordConfirm;
