import { FC, useState } from "react";
import { Formik } from "formik";
import { AuthLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import { Button, Logo } from "../../components/common";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ForgotPasswordPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isSubmitting, ] = useState(false);
    const [message, ] = useState("");

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email(t("error.validation.invalid_email"))
            .required(t("error.validation.required")),
    });

    return (
        <AuthLayout>
            <div className="flex flex-col items-center justify-center h-screen">
                <Formik
                    initialValues={{ email: "" }}
                    onSubmit={async (values) => {
                        try {
                            await axios.post("password/reset/", values);
                        } catch (error) {
                            console.error("Password reset failed:", error);
                        }

                        navigate("/forgot-password-confirm");
                    }}
                    validationSchema={ForgotPasswordSchema}
                >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          errors,
                          touched,
                      }) => (
                        <div className="flex flex-col w-full max-w-96 gap-y-4">
                            <div className="m-auto">
                                <Logo />
                            </div>

                            <h2 className="text-lg font-semibold text-center">
                                {t("title.forgot_password")}
                            </h2>
                            <p className="text-center text-sm text-gray-600">
                                {t("message.forgot_password_instructions")}
                            </p>

                            <div className="flex flex-col gap-y-1">
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6"
                                    >
                                        {t("label.email")}
                                    </label>
                                </div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="email"
                                    required
                                    placeholder="example@company.com"
                                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"
                                />
                                {errors.email && touched.email && (
                                    <div className="text-red text-sm">
                                        {errors.email}
                                    </div>
                                )}
                            </div>

                            <div>
                                <Button
                                    title={t("button.send_reset_link")}
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                />
                            </div>

                            {message && (
                                <p className="text-center text-sm text-green-500">
                                    {message}
                                </p>
                            )}

                            <div className="text-center">
                                <p>
                                    {t("title.remembered_password")}{" "}
                                    <a
                                        href=""
                                        onClick={() => navigate("/login")}
                                        className="text-primary cursor-pointer"
                                    >
                                        {t("button.back_to_login")}
                                    </a>
                                </p>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </AuthLayout>
    );
};

export default ForgotPasswordPage;
